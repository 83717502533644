import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'preact/hooks';
import FaqsList from './FaqsList';
import { SubHeadline } from '../../components/SubHeadline';

export const Faq = ({ headline, color }) => {
  const faqData = [
    {
      value: 'panel1',
      heading: 'Was ist die THG-Quote?',
      detail:
        'Mit der Treibhausgas-Minderungsquote (THG-Quote) möchte die Bundesregierung die CO₂-Emissionen im Straßenverkehr um 25 Prozent bis zum Jahr 2030 mindern. Da rein batterieelektrischen Elektrofahrzeugen (BEV) im Straßenverkehr keine CO₂-Emissionen produzieren, können Halter dieser Fahrzeug seit dem 01.01.2022 die eingesparten CO₂-Emissionen zertifizieren lassen und über Dritte an Mineralölunternehmen vermarkten.',
    },
    {
      value: 'panel2',
      heading: 'Ist die THG-Quote gesetzlich verankert?',
      detail:
        'Ja. Die gesetzliche Grundlage bildet das Bundes-Immissionsschutzgesetz (BlmSchG § 37a) sowie die Verordnung zur Festlegung weiterer Bestimmungen zur Treibhausgasminderung bei Kraftstoffen (38. BImSchV vom 8. Dezember 2017).',
    },
    {
      value: 'panel3',
      heading: 'Wie lange wird es die THG-Quote geben?',
      detail:
        'Nach derzeitigem Kenntnisstand wird die THG-Quote bis zum Jahr 2030 anrechenbar sein. Ein Verlängerung scheint durchaus möglich, da über das Jahr 2030 hinaus weitere Klimaziele erreicht werden sollen.',
    },
    {
      value: 'panel4',
      heading: 'Wer hat Anspruch auf die THG-Quotenprämie?',
      detail:
        'Ausschließlich Halter von rein batterieelektrischen Elektrofahrzeugen (BEV) sind seit dem 01.01.2022 berechtigt, am THG-Quotenhandel teilzunehmen. Dies gilt sowohl für privat, als auch für geschäftlich genutzte E-Fahrzeuge. Sobald in Ihrem Fahrzeug jedoch ein Verbrennungsmotor verbaut ist, beispielsweise bei Plug-in-Hybrid-Fahrzeuge, erfüllen Sie diese Voraussetzung nicht.',
    },
    {
      value: 'panel5',
      heading: 'Welche Fahrzeugklassen sind für die THG-Quote zugelassen?',
      detail:
        'Mit dem 29.07.2023 trat eine überarbeitete THG-Verordnung in Kraft, die freiwillig zugelassene Fahrzeuge wie E-Roller oder Kleinkrafträder vom THG-Quotenhandel ausschließt. <br/><br/><b>Zertifizierbare Fahrzeugklassen:</b> M1, N1, N2, N3, M3, L3e, L3e-A2, L3e-A3, L4e, L4e-A2, L4e-A3, L5e, L5e-A, L5e-B, L7e, L7e-A1, L7e-A2, L7e-B1, L7e-B2, L7e-CP, L7e-CU<br/><br/><b>Nicht mehr zertifizierbare Fahrzeugklassen:</b> L1e, L1e-A, L1e-B, L2e, L2e-P, L2e-U, L3e-A1, L3e (Art des Aufbaus = Feld 4 = B), L4e-A1, L6e-A, L6e-BP, L6e-BU, 24, 25',
    },
    {
      value: 'panel6',
      heading: 'Wie hoch ist die THG-Quotenprämie?',
      detail:
        'Die von uns ausgezahlte THG-Quotenprämie entspricht der Höhe, wie es Ihnen bei der Einreichung der Daten Ihres Elektrofahrzeugs angezeigt wurde. Der Abzug einer Provision erfolgt bei uns nicht.',
    },
    {
      value: 'panel7',
      heading: 'Wann und wie erhalte ich die THG-Quotenprämie?',
      detail:
        'Nach erfolgreicher, interner Prüfung Ihrer Daten durch uns zahlen wir die THG-Quotenprämie per Sofortgutschrift innerhalb von wenigen Tagen auf die angegebene Kontoverbindung aus. ',
    },
    {
      value: 'panel8',
      heading:
        'Was ist der Unterschied zwischen Sofortauszahlung, Fixprämie und ein "bis zu"-Preis?',
      detail:
        'Das THG-Quotenportal bietet nur die Sofortauszahlung an, die unmittelbar nach der erfolgreichen Prüfung Ihrer Daten stattfindet. Es wird der Betrag ohne Abzug ausgezahlt, der zum Zeitpunkt Ihrer Dateneinreichung auf dem THG-Quotenportal angezeigt wurde. Es gibt weiterhin Anbieter, die mit der Fixprämie die Auszahlung eines festen Betrages garantieren. Vorsicht ist bei Anbietern mit sogenannten "bis zu"-Preisen geboten. Bei "bis zu"-Preisen ist weder die Auszahlung, noch die Höhe der THG-Quotenprämie garantiert. Das Vermarktungsrisiko liegt somit in voller Höhe beim Kunden.',
    },
    {
      value: 'panel9',
      heading: 'Was kostet dieser Service?',
      detail: 'Der Service ist für Sie kostenlos.',
    },
    {
      value: 'panel10',
      heading: 'Wie oft erhalte ich die THG-Quotenprämie?',
      detail:
        'Solange Sie Halter eines rein batterieelektrischen Elektrofahrzeugs sind, können Sie Ihre THG-Quote jedes Jahr neu beantragen und vermarkten.',
    },
    {
      value: 'panel11',
      heading: 'Welche Daten werden benötigt?',
      detail:
        'Für die Prüfung und Auszahlung Ihrer THG-Quotenprämie ist die Übermittlung jeweils einer Kopie der Vorderseite Ihres Fahrzeugscheins (Teil 1), Ihre Kontaktdaten und Ihre Bankverbindung notwendig. Sobald diese Daten uns vollständig vorliegen, startet unverzüglich der Vermarktungsprozess. Über den Fortschritt des Vermarktungsprozesses werden Sie stets über Ihre angegebene E-Mailadresse informiert.',
    },
  ];
  useEffect(() => {
    document.getElementById('wrapper').scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }, []);

  const { enqueueSnackbar } = useSnackbar();
  const [tempVehicle, setTempVehicle] = useState(null);

  return (
    <Box sx={{ position: 'relative', mt: 4, mb: 4 }}>
      <SubHeadline text={headline} />
      <Box sx={{ mt: 2 }}>
        <FaqsList faqData={faqData} />
      </Box>
    </Box>
  );
};
