import { Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const SubHeadline = ({ text }) => {
  const theme = useTheme();
  const color = theme.palette.primary.main;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        mb: 2,
        mt: 4,
        width: '100%',
      }}
    >
      <Typography
        variant='h3'
        sx={{
          fontWeight: 400,
          textTransform: 'uppercase',
          fontSize: '1.7rem!important',
          letterSpacing: '-0.5px',
          mb: '5px',
        }}
      >
        {text}
      </Typography>

      <Box
        sx={{ margin: 'auto', width: '200px', border: `1px solid ${color}` }}
      />
    </Box>
  );
};
