import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

export const StepsExplainItem = ({ stepNumber, text }) => {
  const theme = useTheme();
  const color = theme.palette.primary.main;
  return (
    <Box
      sx={{
        mb: 1,
        mt: 1,
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
      }}
    >
      <Box
        sx={{
          borderRadius: '50%',
          width: '40px',
          height: ' 40px',
          fontWeight: 600,
          color: '#FFF',
          backgroundColor: color,
          position: 'relative',
          alignSelf: 'center',
          marginBottom: '16px',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          {stepNumber}
        </Box>
      </Box>

      <Typography sx={{}}>{text}</Typography>
    </Box>
  );
};
