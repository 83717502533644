import { Box } from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTheme } from '@mui/material/styles';

export const CheckItem = ({ text, headline }) => {
  const theme = useTheme();
  const color = theme.palette.primary.main;
  return (
    <Box
      className='checkItem'
      sx={{ display: 'flex', flexDirection: 'row', mt: 2, mb: 2 }}
    >
      <CheckCircleIcon sx={{ color: color, mr: 2 }} />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ fontWeight: 600, mb: 1 }}>{headline}</Box>
        <Box sx={{}}>{text}</Box>
      </Box>
    </Box>
  );
};
