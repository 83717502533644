import Step from "@mui/material/Step";
import ElectricCarIcon from "@mui/icons-material/ElectricCar";
import PersonIcon from "@mui/icons-material/Person";
import CheckIcon from "@mui/icons-material/Check";
import SendIcon from "@mui/icons-material/Send";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";

export const ProcessStepper = ({ activeStep, isMobileView }) => {
  const steps = [
    "Fahrzeugdaten",
    "Kontaktdaten",
    "Daten senden",
    "E-Mail Bestätigung",
  ];

  const CustomConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 16,
      left: "calc(-50% + 16px)",
      right: "calc(50% + 16px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.primary.main,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.primary.main,
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;
    const icons = [
      <ElectricCarIcon key={0} sx={{ width: "18px", height: "18px" }} />,
      <PersonIcon key={1} sx={{ width: "18px", height: "18px" }} />,
      <SendIcon
        key={2}
        sx={{ width: "18px", height: "18px", marginLeft: "3px" }}
      />,
      <MarkEmailReadIcon key={3} sx={{ width: "18px", height: "18px" }} />,
    ];

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {completed ? (
          <CheckIcon sx={{ width: "18px", height: "18px" }} />
        ) : (
          icons[props.icon - 1]
        )}
      </ColorlibStepIconRoot>
    );
  }

  const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 32,
    height: 32,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      background: theme.palette.primary.main,
    }),
    ...(ownerState.completed && {
      background: theme.palette.primary.main,
    }),
  }));

  return (
    <Box sx={{ marginBottom: "40px", marginTop: "40px" }}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={<CustomConnector />}
      >
        {steps.map((label) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              {
                <StepLabel StepIconComponent={ColorlibStepIcon} {...labelProps}>
                  {isMobileView ? "" : label}
                </StepLabel>
              }
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};
