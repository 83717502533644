import { useTheme } from '@mui/material/styles';

// @mui
import { Card, Box, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

// ----------------------------------------------------------------------
function hexToRgb(hex, a) {
  let c = null;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return (
      'rgba(' +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
      ',' +
      a +
      ')'
    );
  }
  throw new Error('Bad Hex');
}
export default function CharitySelectBox({
  logo,
  name,
  shortDescription,
  setSelectedCharity,
  isSelected,
}) {
  const theme = useTheme();
  const isMobileView = !useMediaQuery(theme.breakpoints.up('md'));
  const PRIMARY_MAIN = theme.palette.primary.main;

  return (
    <Card
      onClick={() => setSelectedCharity(name)}
      sx={{
        padding: '15px',
        backgroundColor: isSelected ? `${hexToRgb(PRIMARY_MAIN, 1)}` : `#FFF`,
        color: isSelected ? '#FFF' : 'rgba(0,0,0,0.55)',
        textAlign: 'center',
        cursor: 'pointer',
        borderWidth: isSelected ? '3px 3px 3px 3px' : '0px 3px 3px 3px',
        borderColor: isSelected ? PRIMARY_MAIN : 'rgba(0,0,0,0.05)',
        bordeStyle: 'solid',
        width: '100%',
        minHeight: isMobileView ? 'inherit' : '250px',
        borderRadius: '7px 7px 7px 7px',
        transition: 'all 0.3s',
        overflow: 'hidden',
        border: `1px solid ${PRIMARY_MAIN}`,
        boxShadow: `${hexToRgb(PRIMARY_MAIN, 0.12)} 0px 1px 3px, ${hexToRgb(
          PRIMARY_MAIN,
          0.24
        )} 0px 1px 2px;`,
        '&:hover': {
          boxShadow: `0px 20px 35px -26px ${hexToRgb(PRIMARY_MAIN, 0.65)}`,
        },
      }}
    >
      <Box>
        <Box sx={{ height: '100px', width: '100%' }}>
          {logo && (
            <img
              src={logo}
              alt={name}
              crossOrigin='anonymous'
              style={{ objectFit: 'contain', width: '100%', height: '100px' }}
            />
          )}
        </Box>

        <Typography
          sx={{
            marginTop: {
              xs: '10px',
              md: '0px',
              lineHeight: '23px',
              fontSize: '1.125rem!important',
              margin: '10px 0px!important',
            },
          }}
          variant='h3'
        >
          {name}
        </Typography>
        <Typography sx={{ marginTop: { xs: '10px', md: '0px' } }}>
          {shortDescription}
        </Typography>
      </Box>
    </Card>
  );
}
