//
import Accordion from './Accordion';
import Button from './Button';
import Checkbox from './Checkbox';
import CssBaseline from './CssBaseline';
import Input from './Input';
import Link from './Link';
import LoadingButton from './LoadingButton';
import Popover from './Popover';
import Progress from './Progress';
import Radio from './Radio';
import Select from './Select';
import Slider from './Slider';
import Stepper from './Stepper';
import Switch from './Switch';
import Tooltip from './Tooltip';
import Typography from './Typography';

// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme) {
  return Object.assign(
    Link(theme),
    Input(theme),
    Radio(theme),
    Switch(theme),
    Select(theme),
    Button(theme),
    Slider(theme),
    Stepper(theme),
    Tooltip(theme),
    Popover(theme),
    Checkbox(theme),
    Progress(theme),
    Accordion(theme),
    Typography(theme),
    CssBaseline(theme),
    LoadingButton(theme)
  );
}
