import { useTheme } from '@mui/material/styles';

// @mui
import { Card, Box, Typography } from '@mui/material';
// ----------------------------------------------------------------------
function hexToRgb(hex, a) {
  let c = null;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return (
      'rgba(' +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
      ',' +
      a +
      ')'
    );
  }
  throw new Error('Bad Hex');
}
export default function VehicleClass({
  year,
  submissionYears,
  handleSetSelectedYear,
  isDisabled,
}) {
  const theme = useTheme();
  const PRIMARY_MAIN = theme.palette.primary.main;
  const isSelectedYear = submissionYears && submissionYears.includes(year);
  if (isDisabled) {
    return (
      <Card
        sx={{
          padding: '15px',
          backgroundColor: isSelectedYear
            ? `${hexToRgb(PRIMARY_MAIN, 1)}`
            : `#FFF`,
          color: isSelectedYear ? '#FFF' : 'rgba(0,0,0,0.55)',
          textAlign: 'center',
          borderWidth: '0px 3px 3px 3px',
          borderColor: 'rgba(0,0,0,0.05)',
          bordeStyle: 'solid',
          width: '100%',

          borderRadius: '7px 7px 7px 7px',
          transition: 'all 0.3s',
          overflow: 'hidden',
          border: `1px solid ${PRIMARY_MAIN}`,
          boxShadow: `${hexToRgb(PRIMARY_MAIN, 0.12)} 0px 1px 3px, ${hexToRgb(
            PRIMARY_MAIN,
            0.24
          )} 0px 1px 2px;`,
          '&:hover': {
            boxShadow: `0px 20px 35px -26px ${hexToRgb('#acacac', 0.65)}`,
          },
        }}
      >
        <Box>
          <Typography variant='h3'>{year}</Typography>
        </Box>
      </Card>
    );
  }

  return (
    <Card
      onClick={() => handleSetSelectedYear(year)}
      sx={{
        padding: '15px 0px',
        backgroundColor: isSelectedYear
          ? `${hexToRgb(PRIMARY_MAIN, 1)}`
          : `#FFF`,
        color: isSelectedYear ? '#FFF' : 'rgba(0,0,0,0.55)',
        textAlign: 'center',
        cursor: 'pointer',
        borderWidth: isSelectedYear ? '3px 3px 3px 3px' : '0px 3px 3px 3px',
        borderColor: isSelectedYear ? PRIMARY_MAIN : 'rgba(0,0,0,0.05)',
        bordeStyle: 'solid',
        width: '100%',

        borderRadius: '7px 7px 7px 7px',
        transition: 'all 0.3s',
        overflow: 'hidden',
        border: `1px solid ${PRIMARY_MAIN}`,
        boxShadow: `${hexToRgb(PRIMARY_MAIN, 0.12)} 0px 1px 3px, ${hexToRgb(
          PRIMARY_MAIN,
          0.24
        )} 0px 1px 2px;`,
        '&:hover': {
          boxShadow: `0px 20px 35px -26px ${hexToRgb(PRIMARY_MAIN, 0.65)}`,
        },
      }}
    >
      <Box>
        <Typography
          sx={{
            marginTop: { xs: '10px', md: '0px' },
            textAlign: 'center',
          }}
          variant='h3'
        >
          {year}
        </Typography>
      </Box>
    </Card>
  );
}
