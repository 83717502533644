import { useEffect, useState } from 'preact/hooks';
import { useTheme } from '@mui/material/styles';

import DeleteOutline from '@mui/icons-material/DeleteOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import ReactGA from 'react-ga4';
import AccordionDetails from '@mui/material/AccordionDetails';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AccordionSummary from '@mui/material/AccordionSummary';
import { useSnackbar } from 'notistack';
import FormVehicle from '../FormVehicle';
import { fCurrencyEuro } from '../../utils/formatNumber';
import { NoPreview } from '../../components/upload';
import { SubHeadline } from '../../components/SubHeadline';

import { Fragment } from 'react';

const labelMapping = {
  M1: 'E-PKW',
  LXe: 'E-Zweirad',
  N1: 'E-Nutzfahrzeug',
  N2: 'E-Nutzfahrzeug',
  N3: 'E-Nutzfahrzeug',
  M3: 'E-Bus',
};

const subLabelMapping = {
  M1: 'M1',
  LXe: 'L3e, L4e, L5e, L7e',
  N1: 'N1 bis 3,5t',
  N2: 'N2 ab 3,5t bis 12t',
  N3: 'N3 ab 12t',
  M3: 'M3',
};

export const FormStepOne = ({
  formState,
  setFormState,
  handleNext,
  dataformOptions,
  partner,
  availableSubmissionYears,
  directSubmit,
  charityPartner,
}) => {
  useEffect(() => {
    document.getElementById('wrapper').scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }, []);

  useEffect(() => {
    if (partner && partner.name === 'THG-QUOTENPORTAL') {
      ReactGA.event({
        category: 'Dataform',
        action: 'open_step1',
      });
    }
  }, []);

  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const [tempVehicle, setTempVehicle] = useState(formState.vehicles.at(-1));
  const [tempVehicleDialogOpen, setTempVehicleDialogOpen] = useState(false);
  const [step1Submitted, setStep1Submitted] = useState(false);
  const [sumCharityAmount, setSumCharityAmount] = useState(0);
  const vehicleCount = formState.vehicles.length + 1;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const addVehicle = (data) => {
    const newFormState = formState;
    newFormState['vehicles'] = [...formState.vehicles, data];
    setFormState({ ...newFormState });
    setSumCharityAmount(sumCharityAmount + Number(data.charityAmount));
  };

  const removeVehicle = (indexToDelete) => {
    const newFormState = formState;
    const newVehicles =
      indexToDelete === -1
        ? formState.vehicles.slice(0, -1)
        : formState.vehicles.filter((item, index) => {
            return index !== indexToDelete;
          });
    newFormState['vehicles'] = newVehicles;
    setFormState({ ...newFormState });

    enqueueSnackbar('Fahrzeug entfernt', {
      variant: 'warning',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    });
  };

  const getFileExtension = (filename) => {
    return filename.split('.').pop().toLowerCase();
  };

  const nextStep = () => {
    const noErrorStep1 = Object.values(step1Errors).every((value) => {
      return !value;
    });

    if (noErrorStep1) {
      if (
        tempVehicle.registration_image &&
        tempVehicle.submissionYears &&
        tempVehicle.submissionYears.length > 0
      ) {
        addVehicle(tempVehicle);
        handleNext();
      } else {
        //show dialog
        setTempVehicleDialogOpen(true);
      }
      if (!tempVehicle.registration_image && !tempVehicle.submissionYears) {
        handleNext();
      }
    }
    setStep1Submitted(true);
  };

  const goNextStep = () => {
    handleNext();
    setStep1Submitted(true);
  };

  const step1Errors = {
    submissionYear:
      formState.vehicles.length === 0 &&
      tempVehicle &&
      (!tempVehicle.submissionYears ||
        tempVehicle.submissionYears.length === 0),
    registrationImageFront:
      formState.vehicles.length === 0 &&
      tempVehicle &&
      !tempVehicle.registration_image,
    charity:
      formState.vehicles.length === 0 &&
      tempVehicle &&
      charityPartner &&
      charityPartner.length > 0 &&
      !tempVehicle.selectedCharity,
  };

  return (
    <Box sx={{ position: 'relative', marginBottom: '40px' }}>
      <FormVehicle
        availableSubmissionYears={availableSubmissionYears}
        dataformOptions={dataformOptions}
        key={vehicleCount}
        addVehicle={addVehicle}
        removeVehicle={removeVehicle}
        vehicleCount={vehicleCount}
        setTempVehicle={setTempVehicle}
        formState={formState}
        setFormState={setFormState}
        partner={partner}
        step1Errors={step1Errors}
        step1Submitted={step1Submitted}
        directSubmit={directSubmit}
        charityPartner={charityPartner}
        sumCharityAmount={sumCharityAmount}
      />
      {formState.vehicles.length > 0 && (
        <Box sx={{ marginTop: '40px', marginBottom: '40px' }}>
          <SubHeadline text='Ihre bereits angelegten Fahrzeuge' />

          {formState.vehicles.map((vehicle, index) => {
            return (
              <Accordion key={index} sx={{ boxShadow: 'none', border: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon color='primary' />}
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                  sx={{
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                    borderRadius: '4px',
                    boxShadow: 'none',
                  }}
                >
                  <Box>
                    <Typography sx={{ marginLeft: '10px' }}>
                      <strong>Fahrzeugklasse:</strong>{' '}
                      {`${labelMapping[vehicle.vehicleClass]} (${
                        subLabelMapping[vehicle.vehicleClass]
                      })`}
                    </Typography>

                    <Typography sx={{ marginLeft: '10px' }}>
                      <strong>{`THG-Quotenprämie: `}</strong>

                      {fCurrencyEuro(vehicle.vehiclePayoutPrice)}
                    </Typography>
                    <Typography sx={{ marginLeft: '10px' }}>
                      <strong>{`Abrechnungszeitraum: `}</strong>

                      {`${vehicle.submissionYears.join(', ')}`}
                    </Typography>

                    <Typography sx={{ marginLeft: '10px' }}>
                      <strong>{`Auszahlungstyp: `}</strong>{' '}
                      {vehicle.payoutMode === 'instant'
                        ? 'Sofortauszahlung'
                        : 'Auszahlung nach Bestätigung durch Umweltbundesamt'}
                    </Typography>

                    {vehicle.charityAmount && (
                      <Box>
                        <Typography sx={{ marginLeft: '10px' }}>
                          <strong>{`Auszahlungsbetrag: `}</strong>{' '}
                          {fCurrencyEuro(
                            vehicle.vehiclePayoutPrice - vehicle.charityAmount
                          )}
                        </Typography>
                        <Typography sx={{ marginLeft: '10px' }}>
                          <strong>{`Spendenbetrag: `}</strong>{' '}
                          {fCurrencyEuro(vehicle.charityAmount)}
                        </Typography>
                      </Box>
                    )}
                    {vehicle.selectedCharity && (
                      <Typography sx={{ marginLeft: '10px' }}>
                        <strong>{`Spendenpartner: `}</strong>{' '}
                        {vehicle.selectedCharity}
                      </Typography>
                    )}
                  </Box>

                  <IconButton
                    onClick={() => removeVehicle(index)}
                    sx={{ marginTop: '-8px', marginLeft: '10px' }}
                    color='primary'
                    aria-label='upload picture'
                    component='span'
                  >
                    <DeleteOutline />
                  </IconButton>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    overflow: 'hidden',
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                    borderRadius: '4px',
                    boxShadow: 'none',
                  }}
                >
                  <Grid
                    container
                    spacing={5}
                    justifyContent={{ xs: 'center', md: 'space-between' }}
                    sx={{
                      textAlign: { xs: 'center', md: 'left' },
                      marginBottom: '20px',
                    }}
                  >
                    {vehicle.registration_image && (
                      <Grid item xs={12} md={12}>
                        {getFileExtension(vehicle.registration_image.name) ===
                          'heic' ||
                        getFileExtension(vehicle.registration_image.name) ===
                          'pdf' ? (
                          <NoPreview
                            fileName={vehicle.registration_image.name}
                          />
                        ) : (
                          <img
                            src={`${window.URL.createObjectURL(
                              vehicle.registration_image
                            )}`}
                            style='width: 100%; object-fit: contain; max-height: 300px'
                          />
                        )}
                      </Grid>
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Box>
      )}
      <Fragment>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box sx={{ flex: '1 1 auto' }} />

          <Button
            size='large'
            variant='contained'
            onClick={() => nextStep()}
            sx={{
              color: '#FFF',
              boxShadow: 'none!important',
            }}
          >
            weiter
          </Button>
        </Box>
      </Fragment>
      <Dialog
        open={tempVehicleDialogOpen}
        onClose={() => setTempVehicleDialogOpen(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {'Das aktuelle Fahrzeug wurde nicht vollständig angelegt'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Sind Sie sicher dass Sie fortfahren möchten? Das aktuelle Fahrzeug
            wurde noch nicht vollständig (Bild des Fahrzeugscheins,
            Beantragungsjahr) angelegt. Bitte vervollständigen Sie die
            Antragsdaten oder verwerfen Sie das aktuelle Fahrzeug.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ textTransform: 'none' }}
            onClick={() => setTempVehicleDialogOpen(false)}
          >
            Daten vervollständigen
          </Button>
          <Button onClick={goNextStep} autoFocus>
            Aktuelles Fahrzeug verwerfen
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
