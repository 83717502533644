import breakpoints from './breakpoints';
import shadows, { customShadows } from './shadows';
import typography from './typography';
// @mui
import { ThemeProvider, createTheme } from '@mui/material/styles';
// hooks

//
import componentsOverride from './overrides';

// ----------------------------------------------------------------------



export default function ThemeColorPresets({ children, colorCode }) {
  const theme = createTheme({
    palette: {
      primary: {
        main: colorCode,
      },
    },
    typography,
    breakpoints,
    shadows: shadows.light,
    customShadows: customShadows.light
  })
  theme.components = componentsOverride(theme);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
