// @mui
import { Box, Typography, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// assets
import Iconify from "../Iconify";
import registrationExampleFront from "./Zulassungsbescheinigung-I.1.jpg";

import Image from "../Image";
// ----------------------------------------------------------------------

export default function BlockContent({ label }) {
  const theme = useTheme();
  const PRIMARY_MAIN = theme.palette.primary.main;
  const registrationImage = registrationExampleFront;
  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      direction={{ xs: "column", md: "row" }}
      sx={{ width: 1, textAlign: { xs: "center", md: "left" } }}
    >
      <Iconify
        icon={"mdi:file-upload-outline"}
        width={65}
        height={65}
        color={PRIMARY_MAIN}
      />

      <Box sx={{ p: 3 }}>
        <Typography gutterBottom variant="h6">
          {label}
        </Typography>

        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          Datei hier ablegen oder Computer&nbsp;
          <Typography
            variant="body2"
            component="span"
            sx={{ color: "primary.main", textDecoration: "underline" }}
          >
            durchsuchen
          </Typography>
        </Typography>
      </Box>
      <Image
        backgroundSize={"contain !important"}
        alt="Beispiel"
        src={registrationImage}
        sx={{
          width: { xs: "100%", md: "250px" },
          objectFit: "contain",
          border: `1px solid ${PRIMARY_MAIN}`,
          padding: "5px",
          borderRadius: "5px",
          maxWidth: "330px",
        }}
      />
    </Stack>
  );
}
