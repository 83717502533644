import { Box, Button } from '@mui/material';

export const CTAButton = ({ handleNext }) => {
  return (
    <Box sx={{ mb: 1, mt: 1, alignSelf: 'center' }}>
      <Button
        size='large'
        onClick={handleNext}
        sx={{ marginTop: '20px', color: '#FFF', textTransform: 'none' }}
        variant='contained'
      >
        Jetzt Prämie sichern
      </Button>
    </Box>
  );
};
