import { Typography, Box, TextField, Grid } from '@mui/material';
import { Controller } from 'react-hook-form';
import InputAdornment from '@mui/material/InputAdornment';
import ErrorIcon from '@mui/icons-material/Error';
import CheckIcon from '@mui/icons-material/Check';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { SubHeadline } from '../../components/SubHeadline';
import { isValidIBAN } from 'ibantools-germany';
export const BankDataForm = ({
  errors,
  dirtyFields,
  formValues,
  control,
  touchedFields,
  successFields,
  errorFields,
  handleIbanChange,
  bankData,
}) => {
  return (
    <>
      <Box sx={{ mb: 1, mt: 2, display: 'flex', flexDirection: 'column' }}>
        <SubHeadline text='Bankdaten' />
        <Typography sx={{ textAlign: 'center', mb: 2 }}>
          Ihre Bankdaten für die Auszahlung:
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  InputProps={{
                    endAdornment: (
                      <>
                        {errors &&
                          Object.keys(dirtyFields).includes(
                            'paymentRecipient'
                          ) &&
                          formValues.paymentRecipient && (
                            <>
                              <InputAdornment
                                sx={{ marginRight: '20px' }}
                                position='end'
                              >
                                {errors.paymentRecipient ? (
                                  <ErrorIcon color='error' />
                                ) : (
                                  <CheckIcon color='success' />
                                )}
                              </InputAdornment>
                            </>
                          )}
                      </>
                    ),
                  }}
                  id='paymentRecipient'
                  label={'Kontoinhaber'}
                  variant='outlined'
                  fullWidth
                  error={!!errors.paymentRecipient}
                />
              )}
              rules={{
                required: true,
              }}
              name='paymentRecipient'
              control={control}
              placeholder='Kontoinhaber'
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box>
            <Controller
              render={({ field }) => (
                <TextField
                  InputProps={{
                    endAdornment: (
                      <>
                        {errors &&
                          (Object.keys(touchedFields).includes('iban') ||
                            successFields.includes('iban') ||
                            errorFields.includes('iban')) &&
                          formValues.iban && (
                            <>
                              <InputAdornment
                                sx={{ marginRight: '20px' }}
                                position='end'
                              >
                                {errors.iban ? (
                                  <ErrorIcon color='error' />
                                ) : (
                                  <CheckIcon color='success' />
                                )}
                              </InputAdornment>
                            </>
                          )}
                      </>
                    ),
                  }}
                  {...field}
                  onChange={handleIbanChange}
                  label={'IBAN'}
                  variant='outlined'
                  fullWidth
                  error={!!errors.iban}
                />
              )}
              rules={{
                validate: {
                  required: (value) => {
                    if (!value) {
                      return false;
                    }
                    const text = value.replace(/\s/g, '');

                    return isValidIBAN(text, true);
                  },
                },
                required: true,
              }}
              name='iban'
              control={control}
              placeholder='IBAN'
            />
            <Box
              sx={{
                padding: '10px',
                paddingTop: '0px',
                marginBottom: '20px',
                fontSize: '12px',
                marginTop: '-15px',
                visibility:
                  bankData && bankData.bic && !errors.iban
                    ? 'visible'
                    : 'hidden',
              }}
            >
              <Box
                sx={{
                  margin: '-10px',
                  mt: 1,
                  padding: '10px',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Box>
                  {' '}
                  <AccountBalanceIcon
                    fontSize='small'
                    sx={{ marginRight: '5px', marginTop: '2px' }}
                  />
                </Box>
                <Box>
                  <Box>Bank: {bankData.name}</Box>
                  <Box>BIC: {bankData.bic}</Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
