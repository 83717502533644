import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Alert,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
} from '@mui/material';
import ReactGA from 'react-ga4';

import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { Fragment } from 'preact';
import { useEffect } from 'preact/hooks';
import { Controller, useForm } from 'react-hook-form';
import config from '../../config';
import { UserInputSummary } from './UserInputSummary';
import { VehicleSummary } from './VehicleSummary';
import { InfoBox } from '../../components/InfoBox';
import { SubHeadline } from '../../components/SubHeadline';

export const FormStepThree = ({
  formState,
  setFormState,
  sendData,
  handleBack,
  loading,
  partner,
  directSubmit,
}) => {
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: formState });

  const formValues = watch();

  const theme = useTheme();

  const platformPartner = partner.platformPartner;

  const platformPartnerPath =
    platformPartner === 'Greenfactory' ? 'greenfactory' : 'thgqp';

  useEffect(() => {
    document.getElementById('wrapper').scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }, []);

  useEffect(() => {
    if (partner && partner.name === 'THG-QUOTENPORTAL') {
      ReactGA.event({
        category: 'Dataform',
        action: 'open_step3',
      });
    }
  }, []);

  const onSubmit = (data) => {
    setFormState(data);
    sendData();
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <>
          <Box sx={{ marginBottom: '20px' }}>
            <Grid container spacing={10}>
              <>
                <UserInputSummary formValues={formValues} />
                <VehicleSummary
                  formValues={formValues}
                  formState={formState}
                  partner={partner}
                  theme={theme}
                />
              </>
            </Grid>
          </Box>

          {!directSubmit && (
            <>
              <Box
                sx={{
                  paddingTop: { xs: '10px!important', md: '0px!important' },
                }}
              >
                <Box sx={{ mb: 1, display: 'flex' }}>
                  <SubHeadline text={`Bestätigung`} />
                </Box>
                <Box sx={{ marginBottom: '12px' }}>
                  <InfoBox color={theme.palette.primary.main} />
                </Box>

                <Controller
                  name='concession'
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <FormGroup>
                      <FormControlLabel
                        sx={{ margin: '0px 0px' }}
                        className={errors.concession ? 'error' : ''}
                        control={
                          <Checkbox
                            sx={{ padding: '10px', paddingRight: '20px' }}
                            {...field}
                          />
                        }
                        label='Hiermit bestätige ich, dass ich berechtigt bin, die THG-Quotenprämie für die angegebenen Fahrzeuge zu beantragen.'
                      />
                    </FormGroup>
                  )}
                />
              </Box>

              <Box
                sx={{
                  paddingTop: { xs: '10px!important', md: '0px!important' },
                }}
              >
                {' '}
                <Controller
                  name='concession2'
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <FormGroup>
                      <FormControlLabel
                        sx={{ margin: '0px 0px' }}
                        className={errors.concession2 ? 'error' : ''}
                        control={
                          <Checkbox
                            sx={{ padding: '10px', paddingRight: '20px' }}
                            {...field}
                          />
                        }
                        label='Hiermit bestätige ich, dass für die angegebenen Fahrzeuge im ausgewählten Abrechnungszeitraum noch keine THG-Quotenprämien beantragt wurden.'
                      />
                    </FormGroup>
                  )}
                />
              </Box>
              <Box
                sx={{
                  paddingTop: { xs: '10px!important', md: '0px!important' },
                }}
              >
                <Controller
                  name='privacy'
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <FormGroup>
                      <FormControlLabel
                        sx={{ margin: '0px 0px' }}
                        className={errors.privacy ? 'error' : ''}
                        control={
                          <Checkbox
                            sx={{ padding: '10px', paddingRight: '20px' }}
                            {...field}
                          />
                        }
                        label={
                          <>
                            Ja, ich bin mit den{' '}
                            <Link
                              href={`${config.WEBAPP_HOST}/${platformPartnerPath}/nutzungsbedingungen`}
                              target='_blank'
                              rel='noreferrer'
                            >
                              Nutzungsbedingungen
                            </Link>{' '}
                            einverstanden und habe die{' '}
                            <Link
                              href={`${config.WEBAPP_HOST}/${platformPartnerPath}/datenschutz`}
                              target='_blank'
                              rel='noreferrer'
                            >
                              Datenschutzerklärung
                            </Link>{' '}
                            gelesen.
                          </>
                        }
                      />
                    </FormGroup>
                  )}
                />
              </Box>

              <Box
                sx={{
                  paddingTop: { xs: '10px!important', md: '0px!important' },
                }}
              >
                <Controller
                  name='terms'
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <FormGroup>
                      <FormControlLabel
                        sx={{ margin: '0px 0px' }}
                        className={errors.terms ? 'error' : ''}
                        control={
                          <Checkbox
                            sx={{ padding: '10px', paddingRight: '20px' }}
                            {...field}
                          />
                        }
                        label={
                          <>
                            Ja, ich stimme dem Vertragsabschluss und den damit
                            einhergehenden{' '}
                            <Link
                              href={`${config.WEBAPP_HOST}/thgqp/agb`}
                              target='_blank'
                              rel='noreferrer'
                            >
                              AGB
                            </Link>{' '}
                            der Picsure GmbH zu.
                          </>
                        }
                      />
                    </FormGroup>
                  )}
                />
              </Box>
            </>
          )}
          <Grid
            item
            xs={12}
            md={6}
            sx={{ paddingTop: { xs: '10px!important', md: '0px!important' } }}
          >
            {(errors.terms ||
              errors.privacy ||
              errors.concession ||
              errors.concession2) && (
              <Alert severity='error' sx={{ mb: 3, marginTop: '10px' }}>
                Bitte akzeptieren Sie unsere Bedingungen.
              </Alert>
            )}
          </Grid>
        </>
        <Fragment>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              padding: { xs: '40px 0px 40px 0px', md: '40px 0px' },
            }}
          >
            <Button
              size='large'
              variant='outlined'
              onClick={() => handleBack(false)}
              sx={{ mr: 1 }}
            >
              zurück
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />

            <LoadingButton
              size='large'
              disabled={formState.vehicles.length === 0}
              onClick={handleSubmit(onSubmit)}
              endIcon={<SendIcon />}
              loading={loading}
              loadingPosition='end'
              variant='contained'
              sx={{
                color: '#FFF',
                boxShadow: 'none!important',
              }}
            >
              Daten senden
            </LoadingButton>
          </Box>
        </Fragment>
      </form>
    </Box>
  );
};
