import axios from 'axios';
import config from '../config';

const getApiHost = (hostname) => {
  const IS_GREENFACTORY_DOMAIN =
    hostname === 'greenfactory.msg.group' ||
    hostname === 'staging.greenfactory.msg.group';

  const apiHost = IS_GREENFACTORY_DOMAIN
    ? config.API_HOST_GREENFACTORY
    : config.API_HOST;

  return apiHost;
};

export async function fetchApiKeyFromInitToken(dataformInitKey) {
  const configAxios = {
    headers: { Authorization: `Bearer ${dataformInitKey}` },
  };

  const apiHost = getApiHost(window.location.hostname);
  const response = await axios.get(`${apiHost}/v1/dataform/token`, configAxios);
  if (response.status === 200) {
    return response.data;
  }
}

export async function fetchPartner(dataformToken, inviteId) {
  const configAxios = {
    headers: { Authorization: `Bearer ${dataformToken}` },
  };

  const apiHost = getApiHost(window.location.hostname);

  const url = `${apiHost}/v1/dataform/partner/${inviteId}`;

  const response = await axios.get(url, configAxios);
  if (response.status === 200) {
    return response.data;
  }
}

export async function fetchCharityPartner(dataformToken, partner) {
  const configAxios = {
    headers: { Authorization: `Bearer ${dataformToken}` },
  };

  const apiHost = getApiHost(window.location.hostname);

  const response = await axios.get(
    `${apiHost}/v1/partner/charity/${partner.name}`,
    configAxios
  );
  if (response.status === 200) {
    return response.data;
  }
}
