import { Box, Typography, Grid } from '@mui/material';
import { Headline } from '../../components/Headline';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import { useTheme } from '@mui/material/styles';

export const ContactBox = ({ email, phone }) => {
  const theme = useTheme();
  const color = theme.palette.primary.main;
  return (
    <Box
      sx={{
        mb: 4,
        mt: 4,
        borderRadius: '8px',
        padding: '32px 64px',
        backgroundColor: '#f7f7f7',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      <Typography
        variant='h3'
        sx={{ fontSize: '26px!important', letterSpacing: '1px' }}
      >
        Haben Sie Fragen?
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        Bei Fragen hilft Ihnen unser Servicepartner Elektrovorteil gerne
        persönlich weiter. Kontaktieren Sie den Kundenservice von Montag bis
        Freitag von 08:00 - 18:00 Uhr telefonisch oder per E-Mail.
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6} sx={{}}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <Box sx={{}}>
              <Box
                sx={{
                  borderRadius: '50%',
                  width: '40px',
                  height: ' 40px',
                  fontWeight: 600,
                  color: '#FFF',
                  backgroundColor: color,
                  position: 'relative',
                  alignSelf: 'center',
                  marginBottom: '16px',
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                >
                  <PhoneIcon sx={{}} />
                </Box>
              </Box>
            </Box>
            <Box sx={{}}>
              <Typography>
                <a
                  style={{
                    color: color,
                    textDecoration: 'none',
                    fontWeight: 600,
                    verticalAlign: '-8px',
                    marginLeft: '16px',
                  }}
                  href={`tel:${phone}`}
                >
                  {phone}
                </a>
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} sx={{}}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <Box sx={{}}>
              <Box
                sx={{
                  borderRadius: '50%',
                  width: '40px',
                  height: ' 40px',
                  fontWeight: 600,
                  color: '#FFF',
                  backgroundColor: color,
                  position: 'relative',
                  alignSelf: 'center',
                  marginBottom: '16px',
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                >
                  <MailIcon sx={{}} />
                </Box>
              </Box>
            </Box>
            <Box sx={{}}>
              <Typography>
                <a
                  style={{
                    color: color,
                    textDecoration: 'none',
                    fontWeight: 600,
                    verticalAlign: '-8px',
                    marginLeft: '16px',
                  }}
                  href={`mailto:${email}`}
                >
                  {email}
                </a>
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
