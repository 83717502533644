import { Box } from '@mui/system';
import { Link } from '@mui/material';

export const Footer = ({
  platformPartnerLogo,
  webappHost,
  webappStaticRoute,
}) => {
  return (
    <Box
      className='footer'
      sx={{
        marginTop: 'auto',
        borderTop: '1px solid #eee',
        display: 'flex',
        bottom: '0px',
        flexDirection: { xs: 'column-reverse', md: 'row' },
        WebkitBoxAlign: 'center',
        alignItems: 'center',
        minHeight: { xs: 'inherit', md: '70px' },
        width: '100%',
        mt: 3,
        margin: 'auto',
      }}
    >
      <Box
        sx={{
          maxWidth: '1200px',
          display: 'flex',
          width: '100%',
          margin: 'auto',
          flexDirection: 'row',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexWrap: 'wrap',
            WebkitBoxPack: 'end',
            justifyContent: 'center',
            flexDirection: { xs: 'column', md: 'row' },
            textAlign: 'center',
          }}
        >
          <Link
            href={`${webappHost}/${webappStaticRoute}/faq`}
            target='_blank'
            rel='noreferrer'
            sx={{
              color: '#6f6f6f',
              textDecoration: 'none',
              cursor: 'pointer',
              display: { xs: 'flex', md: 'inline-block' },
              flexBasis: '100%',
              flexDirection: 'column',
              margin: '10px',
              flex: { xs: 1, md: 0 },
            }}
          >
            FAQ
          </Link>

          <Link
            href={`${webappHost}/${webappStaticRoute}/nutzungsbedingungen`}
            target='_blank'
            rel='noreferrer'
            sx={{
              color: '#6f6f6f',
              textDecoration: 'none',
              cursor: 'pointer',
              display: { xs: 'flex', md: 'inline-block' },
              flexBasis: '100%',
              flexDirection: 'column',
              margin: '10px',
              flex: { xs: 1, md: 0 },
            }}
          >
            Nutzungsbedingungen
          </Link>
          <Link
            href={`${webappHost}/${webappStaticRoute}/datenschutz`}
            target='_blank'
            rel='noreferrer'
            sx={{
              color: '#6f6f6f',
              textDecoration: 'none',
              cursor: 'pointer',
              display: { xs: 'flex', md: 'inline-block' },
              flexBasis: '100%',
              flexDirection: 'column',
              margin: '10px',
              flex: { xs: 1, md: 0 },
            }}
          >
            Datenschutz
          </Link>
          <Link
            href={`${webappHost}/${webappStaticRoute}/impressum`}
            target='_blank'
            rel='noreferrer'
            sx={{
              color: '#6f6f6f',
              textDecoration: 'none',
              cursor: 'pointer',
              display: { xs: 'flex', md: 'inline-block' },
              flexBasis: '100%',
              flexDirection: 'column',
              margin: '10px',
              flex: { xs: 1, md: 0 },
            }}
          >
            Impressum
          </Link>
        </Box>
      </Box>
    </Box>
  );
};
