import { Box, Typography } from '@mui/material';
import { SubHeadline } from '../../components/SubHeadline';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export const TextBox = ({ headline }) => {
  const theme = useTheme();

  const isMobileView = !useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box
      sx={{
        mb: 4,
        mt: 4,
        ml: isMobileView ? 1 : 8,
        mr: isMobileView ? 1 : 8,
        textAlign: 'center',
      }}
    >
      <SubHeadline text={headline} />

      <Typography sx={{ mb: 2 }}>
        Mit Ihrem E-Auto tragen Sie nicht nur zum Umwelt- und Klimaschutz bei,
        sondern können sich auch noch für Ihre eingesparten CO2-Emissionen
        <b> finanziell belohnen</b>.
      </Typography>
      <Typography sx={{ mb: 2 }}>
        Mit der Übertragung Ihrer THG-Quote auf unser Vermarktungsportal,
        vermarkten wir Ihre eingesparten CO2-Emissionen
        <b> zu attraktiven Preisen</b> und gewährleisten per
        <b> Sofortauszahlung</b> die Gutschrift Ihrer THG-Quotenprämie
        <b> innerhalb von 3 – 5 Tagen</b> auf Ihr Konto.
      </Typography>
      <Typography sx={{ mb: 2 }}>
        Sie erhalten unsere Prämien in <b>voller Höhe</b>, wie sie zum Zeitpunkt
        der Einreichung ausgewiesen wurde - <b>ohne</b> Abzug, <b>ohne</b>{' '}
        Vermarktungsrisiko, <b>ohne</b> Abo-Modelle und vor allem <b>ohne</b>{' '}
        versteckte Laufzeitverlängerungen in den Folgejahren.
      </Typography>
      <Typography sx={{ mb: 2 }}>
        <b>
          Wir sind Ihr zuverlässiger Partner in der Vermarktung Ihrer THG-Quote!
        </b>
      </Typography>
    </Box>
  );
};
