import { useTheme } from '@mui/material/styles';
// @mui
import { Card, Box, Typography } from '@mui/material';
import Iconify from '../Iconify';
import { fCurrencyEuro } from '../../utils/formatNumber';

export function fNumberFull(number) {
  return Number(parseInt(number, 10)).toLocaleString('de-DE');
}
// ----------------------------------------------------------------------
function hexToRgb(hex, a) {
  let c = null;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return (
      'rgba(' +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
      ',' +
      a +
      ')'
    );
  }
  throw new Error('Bad Hex');
}
export default function VehicleClass({
  vehicleClass,
  selectedVehicleClass,
  handleSetSelectedVehicleClass,
  partner,
  instantPayout,
  multiplePayoutModes,
}) {
  const theme = useTheme();
  const PRIMARY_MAIN = theme.palette.primary.main;
  const isSelectedVehicleClass = vehicleClass === selectedVehicleClass;
  const equalPayout = partner.dataformOptions?.equalPayout;

  const iconMapping = {
    M1: (
      <Iconify
        icon={'mdi:car-hatchback'}
        width={70}
        height={70}
        color={isSelectedVehicleClass ? '#FFF' : PRIMARY_MAIN}
      />
    ),
    LXe: (
      <Iconify
        icon={'mdi:motorbike'}
        width={70}
        height={70}
        color={isSelectedVehicleClass ? '#FFF' : PRIMARY_MAIN}
      />
    ),
    N1: (
      <Iconify
        icon={'mdi:van-passenger'}
        width={70}
        height={70}
        color={isSelectedVehicleClass ? '#FFF' : PRIMARY_MAIN}
      />
    ),
    N2: (
      <Iconify
        icon={'mdi:van-utility'}
        width={70}
        height={70}
        color={isSelectedVehicleClass ? '#FFF' : PRIMARY_MAIN}
      />
    ),
    N3: (
      <Iconify
        icon={'mdi:truck'}
        width={70}
        height={70}
        color={isSelectedVehicleClass ? '#FFF' : PRIMARY_MAIN}
      />
    ),
    M3: (
      <Iconify
        icon={'mdi:bus-side'}
        width={70}
        height={70}
        color={isSelectedVehicleClass ? '#FFF' : PRIMARY_MAIN}
      />
    ),
  };
  const payoutPrices = instantPayout
    ? partner.payoutPrices
    : partner.payoutPricesNotInstant;
  const priceMapping = {
    M1: payoutPrices['M1'],
    LXe: payoutPrices['LXe'],
    N1: payoutPrices['N1'],
    N2: payoutPrices['N2'],
    N3: payoutPrices['N3'],
    M3: payoutPrices['M3'],
  };

  const labelMapping = {
    M1: 'E-PKW',
    LXe: 'E-Zweirad',
    N1: 'E-Nutzfahrzeug',
    N2: 'E-Nutzfahrzeug',
    N3: 'E-Nutzfahrzeug',
    M3: 'E-Bus',
  };

  const subLabelMapping = {
    M1: 'M1',
    LXe: 'L3e, L4e, L5e, L7e',
    N1: 'N1 (bis 3,5t)',
    N2: 'N2 (ab 3,5t bis 12t)',
    N3: 'N3 (ab 12t)',
    M3: 'M3',
  };

  return (
    <Card
      onClick={() => handleSetSelectedVehicleClass(vehicleClass)}
      sx={{
        padding: '20px',
        backgroundColor: isSelectedVehicleClass
          ? `${hexToRgb(PRIMARY_MAIN, 1)}`
          : `#FFF`,
        color: isSelectedVehicleClass ? '#FFF' : 'rgba(0,0,0,0.55)',
        textAlign: 'center',
        cursor: 'pointer',
        borderWidth: isSelectedVehicleClass
          ? '3px 3px 3px 3px'
          : '0px 3px 3px 3px',
        borderColor: isSelectedVehicleClass ? PRIMARY_MAIN : 'rgba(0,0,0,0.05)',
        bordeStyle: 'solid',
        width: '100%',
        // boxShadow: 'none',
        borderRadius: '7px 7px 7px 7px',
        transition: 'all 0.3s',
        overflow: 'hidden',
        border: `1px solid ${PRIMARY_MAIN}`,
        boxShadow: `${hexToRgb(PRIMARY_MAIN, 0.12)} 0px 0px 0px, ${hexToRgb(
          PRIMARY_MAIN,
          0.24
        )} 0px 0px 0px;`,
        '&:hover': {
          boxShadow: `0px 20px 35px -26px ${hexToRgb(PRIMARY_MAIN, 0.65)}`,
        },
      }}
    >
      <Box>
        <Typography variant='h6'>{labelMapping[vehicleClass]}</Typography>
        <Typography>{subLabelMapping[vehicleClass]}</Typography>
        <Box sx={{}}>{iconMapping[vehicleClass]}</Box>
      </Box>
      <Box>
        {!multiplePayoutModes && (
          <Box>
            {' '}
            <Typography sx={{ fontWeight: '700', fontSize: '1.3rem' }}>
              {fCurrencyEuro(Math.round(priceMapping[vehicleClass]))}
            </Typography>
            {instantPayout ? (
              <Typography>Sofortauszahlung</Typography>
            ) : (
              <Typography>
                Auszahlung nach{' '}
                <span style={{ whiteSpace: 'nowrap' }}>UBA-Bestätigung*</span>
              </Typography>
            )}
            {equalPayout ? <Typography>(inkl. MwSt.)</Typography> : <></>}
          </Box>
        )}
      </Box>
    </Card>
  );
}
