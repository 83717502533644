import { Typography, Box } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

export const Headline = ({ text }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        variant='h1'
        sx={{
          fontSize: '26px!important',
          letterSpacing: '1px',
          mt: 1,
          mb: 1,
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};
