import './style/style.css';
import Form from './Form/index';
import NotistackProvider from './NotistackProvider';
import CircularProgress from '@mui/material/CircularProgress';

let dataformKey = null;
let dataformInitKey = null;
let externalID = null;
let directSubmit = false;
let thgInvite = false;
if (typeof window !== 'undefined') {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  if (params.eid) {
    // JWT
    externalID = params.eid;
  }

  if (params.dk) {
    // JWT
    dataformKey = params.dk;
  }
  if (params.dik) {
    // JWT
    dataformInitKey = params.dik;
  }
  if (params.directSubmit) {
    // directSubmit flag
    directSubmit = params.directSubmit;
  }
  if (params.thgInvite) {
    // axa flag
    thgInvite = params.thgInvite;
  }
}

const App = () => (
  <div id='wrapper'>
    {!dataformKey && !dataformInitKey ? (
      <CircularProgress
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      />
    ) : (
      <NotistackProvider>
        <Form
          dataformKey={dataformKey}
          dataformInitKey={dataformInitKey}
          externalID={externalID}
          directSubmit={directSubmit}
          thgInvite={thgInvite}
        />
      </NotistackProvider>
    )}
  </div>
);

export default App;
