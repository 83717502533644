import { Box, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'preact/hooks';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import config from '../../config';

export const Banner = ({ actualYear, partner }) => {
  const theme = useTheme();
  const color = theme.palette.primary.main;
  const isMobileView = !useMediaQuery(theme.breakpoints.up('md'));
  const platformPartner = partner.platformPartner;
  const headLinePrice = Math.round(partner.payoutPrices['M1']);

  const apiHost =
    platformPartner === 'Greenfactory'
      ? config.API_HOST_GREENFACTORY
      : config.API_HOST;
  return (
    <Box
      sx={{
        display: 'flex',
        padding: '40px',
        minHeight: '580px',
        position: 'relative',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        bgcolor: 'background.neutral',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${apiHost}/static/assets/thankyou.jpg)`,
        borderRadius: '8px',
        mb: 4,
        mt: 4,
      }}
    >
      <Box
        sx={{
          background: `linear-gradient(rgba(255,255,255,.9),rgba(255,255,255,.9)) ${color}`,
          minWidth: '300px',
          opacity: 0.9,
          maxWidth: '300px',
          p: 2,
          position: 'absolute',
          left: isMobileView ? '15px' : '80px',
          top: '80px',
          borderRadius: '8px',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', opacity: 1 }}>
          <Typography
            variant='h4'
            sx={{
              fontWeight: 400,
              textTransform: 'uppercase',
              fontSize: '1.7rem!important',
              letterSpacing: '-0.5px',
              textTransform: 'uppercase',
              lineHeight: 1.2,
              mb: 2,
            }}
          >
            FÜR DIESES JAHR SAGEN WIR: "DANKE!".
          </Typography>
          <Typography sx={{ mb: 2 }}>
            Das aktuelle Quotenjahr {actualYear} ist abgeschlossen, daher können
            wir <b>keine weiteren THG-Quoten</b> mehr annehmen.
          </Typography>
          <Typography sx={{ mb: 2 }}>
            Wir freuen uns auf ein baldiges Wiedersehen im kommenden Quotenjahr.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
