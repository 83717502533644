import bicNames from './data/bic.json';

const { ibanToBic } = require('iban-to-bic');

var getBankNameFromIban = function (iban) {
  // Return an object with a Bic code and bank name
  const BIC = ibanToBic(iban);
  const bankName = bicNames[BIC];

  return {
    iban,
    bic: BIC,
    name: bankName,
  };
};

export default getBankNameFromIban;
