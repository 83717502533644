import { Grid, Typography, Card } from '@mui/material';
import { useState } from 'preact/hooks';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import VehicleClass from '../../components/vehicle/VehicleClass';
import { SubHeadline } from '../../components/SubHeadline';
import Iconify from '../../components/Iconify';
import { fCurrencyEuro } from '../../utils/formatNumber';

function hexToRgb(hex, a) {
  let c = null;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join('')}`;
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${a})`;
  }
  throw new Error('Bad Hex');
}

export const VehicleForm = ({
  directSubmit,
  dataformOptions,
  partner,
  selectedVehicleClass,
  handleSetSelectedVehicleClass,
  handleSetSelectedPayoutMode,
  selectedPayoutMode,
}) => {
  const theme = useTheme();
  const PRIMARY_MAIN = theme.palette.primary.main;
  // const availableVehicleClasses = directSubmit
  //   ? ['M1', 'LXe', 'N1', 'N2', 'N3', 'M3']
  //   : dataformOptions.availableVehicleClasses;

  const availableVehicleClasses = dataformOptions.availableVehicleClasses;

  const payoutModes = partner.dataformOptions?.availablePayoutModes;
  const multiplePayoutModes = payoutModes && payoutModes.length > 1;
  const equalPayout = partner.dataformOptions?.equalPayout;

  const renderPayputMethods = (payoutModes) => {
    return (
      <Box>
        <Box sx={{ mb: 2, display: 'flex', flexDirection: 'column' }}>
          <SubHeadline text='Auszahlungsmodus' subText='' />
          <Typography sx={{ textAlign: 'center', mb: '32px' }}>
            Wie schnell möchten Sie Ihre Auszahlung erhalten?
          </Typography>
        </Box>

        <Grid
          container
          spacing={2}
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          {payoutModes.map((payoutMode) => {
            const payoutPrice =
              payoutMode === 'instant'
                ? partner.payoutPrices[selectedVehicleClass]
                : partner.payoutPricesNotInstant[selectedVehicleClass];

            return (
              <Grid
                key={payoutMode}
                item
                xs={12}
                md={6}
                sx={{
                  mb: 3,
                  paddingTop: { xs: '0px!important', md: '64px' },
                }}
              >
                <Card
                  onClick={() => handleSetSelectedPayoutMode(payoutMode)}
                  sx={{
                    padding: '20px',
                    backgroundColor:
                      selectedPayoutMode === payoutMode
                        ? `${hexToRgb(PRIMARY_MAIN, 1)}`
                        : `#FFF`,
                    color:
                      selectedPayoutMode === payoutMode
                        ? '#FFF'
                        : 'rgba(0,0,0,0.55)',
                    textAlign: 'center',
                    cursor: 'pointer',
                    borderWidth:
                      selectedPayoutMode === payoutMode
                        ? '3px 3px 3px 3px'
                        : '0px 3px 3px 3px',
                    borderColor:
                      selectedPayoutMode === payoutMode
                        ? PRIMARY_MAIN
                        : 'rgba(0,0,0,0.05)',
                    bordeStyle: 'solid',
                    width: '100%',
                    // boxShadow: 'none',
                    borderRadius: '7px 7px 7px 7px',
                    transition: 'all 0.3s',
                    overflow: 'hidden',
                    border: `1px solid ${PRIMARY_MAIN}`,
                    boxShadow: `${hexToRgb(
                      PRIMARY_MAIN,
                      0.12
                    )} 0px 0px 0px, ${hexToRgb(
                      PRIMARY_MAIN,
                      0.24
                    )} 0px 0px 0px;`,
                    '&:hover': {
                      boxShadow: `0px 20px 35px -26px ${hexToRgb(
                        PRIMARY_MAIN,
                        0.65
                      )}`,
                    },
                  }}
                >
                  <Box>
                    <Typography variant='h6'>
                      {payoutMode === 'instant'
                        ? 'Sofortauszahlung'
                        : 'Auszahlung nach Bestätigung durch Umweltbundesamt'}
                    </Typography>
                    <Typography>
                      {' '}
                      {payoutMode === 'instant'
                        ? '(3-5 Werktage)'
                        : '(aktuell ca. 4-5 Monate)'}
                    </Typography>

                    <Box sx={{ mt: 2 }}>
                      {' '}
                      <Iconify
                        width={48}
                        height={48}
                        icon={'mdi:instant-cash'}
                      />
                    </Box>
                  </Box>
                  <Typography sx={{ fontWeight: '700', fontSize: '2rem' }}>
                    {fCurrencyEuro(Math.round(payoutPrice))}
                  </Typography>
                  {equalPayout ? <Typography>(inkl. MwSt.)</Typography> : <></>}
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  };

  return (
    <>
      <Box>
        <Box sx={{ mb: 2, display: 'flex', flexDirection: 'column' }}>
          <SubHeadline text='Fahrzeugklasse' subText='' />
          <Typography sx={{ textAlign: 'center', mb: '16px' }}>
            Wählen Sie die Fahrzeugklasse (Fahrzeugschein Feld J):
          </Typography>
        </Box>

        <Box sx={{ marginBottom: '20px', marginTop: '20px' }}>
          <Grid
            container
            spacing={2}
            justifyContent={{ xs: 'center', md: 'space-between' }}
            sx={{ textAlign: { xs: 'center', md: 'left' } }}
          >
            {availableVehicleClasses.map((vehicleClass, index) => (
              <Grid
                key={index}
                item
                xs={12}
                md={12 / availableVehicleClasses.length}
                sx={{
                  mb: 3,
                  paddingTop: { xs: '0px!important', md: '64px' },
                }}
              >
                <VehicleClass
                  multiplePayoutModes={multiplePayoutModes}
                  partner={partner}
                  instantPayout={selectedPayoutMode === 'instant'}
                  vehicleClass={vehicleClass}
                  selectedVehicleClass={selectedVehicleClass}
                  handleSetSelectedVehicleClass={handleSetSelectedVehicleClass}
                />
              </Grid>
            ))}
          </Grid>
          {Boolean(
            !multiplePayoutModes && selectedPayoutMode !== 'instant'
          ) && (
            <Typography sx={{ textAlign: 'center', mb: '16px' }}>
              * Aktuelle Bearbeitungszeit des Umweltbundesamtes (UBA) ca. 4-5
              Monate.
            </Typography>
          )}
        </Box>
      </Box>
      {multiplePayoutModes ? renderPayputMethods(payoutModes) : <></>}
    </>
  );
};
