import { useTheme } from '@mui/system';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import flags from 'react-phone-number-input/flags';

export const CustomPhoneInput = (props) => {
  const theme = useTheme();
  const PRIMARY_MAIN = theme.palette.primary.main;

  const handleFocus = () => {
    document.querySelector('.container').classList.add('focused');
  };

  const handleBlur = () => {
    document.querySelector('.container').classList.remove('focused');
  };

  return (
    <>
      {props.error ? (
        <style>
          {`
        
        .PhoneInputInput {
          line-height: 1.4375em;

    font-family: Arimo,sans-serif;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    position: relative;
    border-radius: 4px;
          outline: none;
          border: none;
          padding: 14.5px 0px;

          font-size: 1rem;
          color: rgb(217,57,62);
        }

        .PhoneInputInput::placeholder {
          color: rgb(217,57,62);
        }

         .container {
          cursor: text;
          border: 1px solid rgb(194,63,56);

          padding: 1px 14px;
          border-radius: 4px;
          position: relative;
        }


        .container.focused {
          border: 2px solid ${PRIMARY_MAIN};
          padding: 0px 13px;
          border-radius: 4px;
          position: relative;
        }


      



        .adornment {
          position: absolute;
          right: 12px;
          top: 50%;

        }

       
      `}
        </style>
      ) : (
        <style>
          {`
        .PhoneInputInput {

          line-height: 1.4375em;

    font-family: Arimo,sans-serif;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    position: relative;
    border-radius: 4px;
        padding-right: 14px;

          outline: none;
          border: none;
          font-size: 1rem;
          padding: 14.5px 0px;
        }



        .container {
          cursor: text;
          border: 1px solid rgb(196,196,196);

          padding: 1px 14px;
          border-radius: 4px;
          position: relative;
        }

        .container:hover {
          border-color: rgb(33,33,33)
        }

        .container.focused {
          border: 2px solid ${PRIMARY_MAIN};
          padding: 0px 13px;
          border-radius: 4px;
          position: relative;
        }


        .adornment {
          position: absolute;
          right: 12px;
          top: 50%;

        }
      `}
        </style>
      )}
      <div className='container'>
        <PhoneInput
          onFocus={handleFocus}
          onBlur={handleBlur}
          international
          countries={['DE']}
          labels={{ DE: 'Deutschland' }}
          countryCallingCodeEditable={false}
          defaultCountry='DE'
          // countrySelectProps={{ unicodeFlags: true }}
          onChange={props.onChange}
          flags={flags}
          value={props.value}
        />
        <div className='adornment'>{props.InputProps.endAdornment}</div>
      </div>
    </>
  );
};
