// @mui
import {
  Accordion,
  Typography,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
// components
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReactHtmlParser from 'react-html-parser';

// ----------------------------------------------------------------------

export default function FaqsList({ faqData }) {
  return (
    <>
      {faqData.map((accordion) => (
        <Accordion key={accordion.value}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon width={20} height={20} />}
          >
            <Typography variant='subtitle1'>{accordion.heading}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{ReactHtmlParser(accordion.detail)}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
}
