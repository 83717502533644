import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import Popover from '@mui/material/Popover';
import { useState } from 'preact/hooks';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import config from '../config';

export const Header = ({
  webappHost,
  partner,
  securedIntegration,
  headlineYear,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const isMobileView = !useMediaQuery(theme.breakpoints.up('md'));

  const PRIMARY_MAIN = theme.palette.primary.main;
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const IS_GREENFACTORY_DOMAIN =
    window.location.hostname === 'greenfactory.msg.group' ||
    window.location.hostname === 'staging.greenfactory.msg.group';

  const apiHost = IS_GREENFACTORY_DOMAIN
    ? config.API_HOST_GREENFACTORY
    : config.API_HOST;
  return (
    <Box
      className='header'
      sx={{
        display: 'flex',
        WebkitBoxAlign: 'center',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        position: 'relative',
      }}
    >
      <Box
        className='logo'
        sx={{
          marginBottom: '20px',
          overflow: 'hidden',
          display: 'block',
          lineHeight: 1,
          display: 'flex',
        }}
      >
        <img
          crossOrigin='anonymous'
          style={{ height: '60px' }}
          src={`${apiHost}/static/logo/${
            partner ? partner.name.toLowerCase() : 'thg-quotenportal'
          }_logo.svg`}
        />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          flex: '0 1 auto',
        }}
      >
        {/* <Typography
          sx={{
            ml: 4,
            letterSpacing: '0px',
            fontSize: { xs: '18px', md: '22px' },
            margin: { xs: '0px', md: '16px 0px' },
          }}
          variant='h3'
        >
          {securedIntegration
            ? `THG-Prämie für ${headlineYear} als Sofortprämie sichern.`
            : `THG-Prämie für ${headlineYear} sichern.`}
        </Typography> */}
      </Box>
      {partner && partner.showHelp && (
        <Box
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup='true'
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          sx={{
            height: '30px',
            cursor: 'pointer',
            flex: '0 1 auto',
            marginLeft: 'auto',
            mt: '20px',
          }}
        >
          <Typography
            sx={{
              ml: 4,
              letterSpacing: '0px',
              fontSize: { xs: '14px', md: '16px' },
              margin: { xs: '0px', md: '0px 0px', mt: '20px' },
            }}
            variant='h5'
          >
            {!isMobileView && <>Haben Sie Fragen?</>}
            <HelpOutlineIcon sx={{ verticalAlign: '-5px', ml: 1 }} />
          </Typography>
          <Popover
            id='mouse-over-popover'
            sx={{
              pointerEvents: 'none',
              maxWidth: '450px',
              minWidth: '350px',
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            anchorPosition={{
              left: -100,
              top: 0,
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Box sx={{ minWidth: '375px', padding: 2 }}>
              <Typography
                sx={{ fontSize: '16px', p: 1, fontWeight: 600, pb: 0 }}
              >
                Wir sind für Sie da
              </Typography>
              <Typography sx={{ p: 1, fontSize: '14px' }}>
                Für Fragen stehe wir Ihnen von Montag bis Freitag von{' '}
                <b>08:00 - 18:00 Uhr</b> zur Verfügung. Rufen Sie uns an oder
                senden Sie eine <b>E-Mail</b>.
              </Typography>
              <Box sx={{ p: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                  <PhoneIcon sx={{ color: PRIMARY_MAIN, fontSize: '20px' }} />{' '}
                  <a
                    href={`tel:${partner.phone}`}
                    style={{
                      color: `${PRIMARY_MAIN}`,
                      marginLeft: '8px',
                      textDecoration: 'none',
                      cursor: 'pointer',
                      fontSize: '15px',
                    }}
                  >
                    {partner.phone}
                  </a>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <EmailIcon sx={{ color: PRIMARY_MAIN, fontSize: '20px' }} />{' '}
                  <a
                    href={`mailto:${partner.email}`}
                    style={{
                      color: `${PRIMARY_MAIN}`,
                      marginLeft: '8px',
                      textDecoration: 'none',
                      cursor: 'pointer',
                      fontSize: '15px',
                    }}
                  >
                    {partner.email}
                  </a>
                </Box>
              </Box>
            </Box>
          </Popover>
        </Box>
      )}
    </Box>
  );
};
