import { alpha } from '@mui/material/styles';
import { Box, Paper, Typography } from '@mui/material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
// utils

// ----------------------------------------------------------------------


export default function NoPreview({fileName}) {
  return (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        mx: 2,
        bgcolor: (theme) => alpha(theme.palette.info.main, 0.08),
      }}
    >
          <Box sx={{ my: 1 }}>
            <Typography variant="subtitle2" noWrap>
             <InsertDriveFileIcon/>
            {fileName}
            </Typography>
          </Box>
    </Paper>
  );
}
