import { Typography, Box } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

export const InfoBox = ({ html, color }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        padding: '6px 16px',
        borderRadius: '4px',
        border: `1px solid ${color}`,
        mt: 2,
      }}
    >
      <ErrorIcon
        sx={{
          fontSize: '32px',
          opacity: 0.9,
          mr: '12px',
          mt: '15px',
          color: color,
        }}
      />
      <Typography sx={{ padding: '8px 0', fontSize: '1rem' }}>
        <b>
          Wichtiger Hinweis: Die THG-Quotenprämie kann pro Fahrzeug nur einmal
          jährlich vom Fahrzeughalter beantragt werden.
        </b>{' '}
        Stellen Sie daher vor Einreichung Ihrer Daten sicher, dass weder durch
        den vorherigen Halter, noch durch einen anderen Anbieter die THG-Quote
        für den ausgewählten Abrechnungszeitraum beantragt worden ist, um
        Doppeleinreichungen zu vermeiden.
      </Typography>
    </Box>
  );
};
