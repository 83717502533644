import { Grid, Box, Typography, Paper } from '@mui/material';
import { SubHeadline } from '../../components/SubHeadline';

export const UserInputSummary = ({ formValues }) => {
  return (
    <>
      <Grid item xs={12} md={12}>
        {' '}
        <Box>
          <Box sx={{ mb: 1, display: 'flex' }}>
            <SubHeadline text='Eingaben' />
          </Box>
        </Box>
        <Paper
          sx={{
            padding: '10px 20px',
            width: '100%',
            border: '1px solid rgba(0, 0, 0, 0.12)',
            borderRadius: '4px',
          }}
          variant='outlined'
        >
          <Box
            sx={{
              display: 'flex',
              paddingBottom: '8px',
              paddingTop: '8px',
              borderBottom: '1px solid #f4f4f4',
            }}
          >
            <strong>Beantragt als:</strong>
            <Box sx={{ textAlign: 'right', flex: 1 }}>
              {formValues.type === 'b2b' ? 'Firma' : 'Privatperson'}
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              paddingBottom: '8px',
              paddingTop: '8px',
              borderBottom: '1px solid #f4f4f4',
            }}
          >
            <strong>Vorname:</strong>
            <Box sx={{ textAlign: 'right', flex: 1 }}>
              {formValues.firstName}
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              paddingBottom: '8px',
              paddingTop: '8px',
              borderBottom: '1px solid #f4f4f4',
            }}
          >
            <strong>Nachname:</strong>
            <Box sx={{ textAlign: 'right', flex: 1 }}>
              {formValues.lastName}
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              paddingBottom: '8px',
              paddingTop: '8px',
              borderBottom: '1px solid #f4f4f4',
            }}
          >
            <strong>Strasse:</strong>
            <Box sx={{ textAlign: 'right', flex: 1 }}>
              {formValues.street} {formValues.housenumber}
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              paddingBottom: '8px',
              paddingTop: '8px',
              borderBottom: '1px solid #f4f4f4',
            }}
          >
            <strong>Stadt:</strong>
            <Box sx={{ textAlign: 'right', flex: 1 }}>
              {formValues.zip} {formValues.city}
            </Box>
          </Box>

          {formValues.type === 'b2b' && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  paddingBottom: '8px',
                  paddingTop: '8px',
                  borderBottom: '1px solid #f4f4f4',
                }}
              >
                <strong>Firmenname:</strong>
                <Box sx={{ textAlign: 'right', flex: 1 }}>
                  {formValues.companyName}
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  paddingBottom: '8px',
                  paddingTop: '8px',
                  borderBottom: '1px solid #f4f4f4',
                }}
              >
                <strong>Umsatzsteuerpflichtig:</strong>
                <Box sx={{ textAlign: 'right', flex: 1 }}>
                  {formValues.salesTax === true ? 'Ja' : 'Nein'}
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  paddingBottom: '8px',
                  paddingTop: '8px',
                  borderBottom: '1px solid #f4f4f4',
                }}
              >
                <strong>Ust.-ID:</strong>
                <Box sx={{ textAlign: 'right', flex: 1 }}>
                  {formValues.taxId}
                </Box>
              </Box>
            </>
          )}

          <Box
            sx={{
              display: 'flex',
              paddingBottom: '8px',
              paddingTop: '8px',
              borderBottom: '1px solid #f4f4f4',
            }}
          >
            <strong>E-Mail-Adresse:</strong>
            <Box sx={{ textAlign: 'right', flex: 1 }}>{formValues.email}</Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              paddingBottom: '8px',
              paddingTop: '8px',
              borderBottom: '1px solid #f4f4f4',
            }}
          >
            <strong>Telefon:</strong>
            <Box sx={{ textAlign: 'right', flex: 1 }}>{formValues.phone}</Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              paddingBottom: '8px',
              paddingTop: '8px',
              borderBottom: '1px solid #f4f4f4',
            }}
          >
            <strong>Kontoinhaber:</strong>
            <Box sx={{ textAlign: 'right', flex: 1 }}>
              {formValues.paymentRecipient}
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              paddingBottom: '8px',
              paddingTop: '8px',
            }}
          >
            <strong>IBAN:</strong>
            <Box sx={{ textAlign: 'right', flex: 1 }}>
              {formValues.iban
                .replace(/[^\dA-Z]/g, '')
                .replace(/(.{4})/g, '$1 ')}
            </Box>
          </Box>
        </Paper>
      </Grid>
    </>
  );
};
