import { Box, Typography } from '@mui/material';
import { SubHeadline } from '../../components/SubHeadline';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link } from '@mui/material';
export const TextBox = ({ headline, actualYear, partner }) => {
  const theme = useTheme();

  const isMobileView = !useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box
      sx={{
        mb: 4,
        mt: 4,
        ml: isMobileView ? 1 : 8,
        mr: isMobileView ? 1 : 8,
        textAlign: 'center',
      }}
    >
      <SubHeadline text={headline} />

      <Typography sx={{ mb: 2 }}>
        Die Quotenprämien für das THG-Quotenjahr {actualYear} stehen aktuell
        noch nicht fest.
      </Typography>
      <Typography sx={{ mb: 2 }}>
        Aber wir geben unser Bestes, Ihnen auch für das kommende Quotenjahr
        wieder attraktive THG-Quotenprämien per Sofortauszahlung anbieten zu
        können.
      </Typography>
      <Typography sx={{ mb: 2 }}>
        <b>
          Sobald es hierzu Neuigkeiten gibt, informieren wir Sie gern
          {partner.name === 'LVM' ? (
            <Link
              href='https://lvm.de/thgquote'
              target='_blank'
              rel='noreferrer'
            >
              {' '}
              hier
            </Link>
          ) : (
            <></>
          )}
          .
        </b>
      </Typography>
    </Box>
  );
};
